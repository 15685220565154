import { ADD_DATA, DEL_DATA, UPD_DATA } from "./actions";

const initialState = {
  data: [],
  idEvent: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DATA:
      console.log()
      return { ...state, data: [...state.data,  (action.payload.idEvent = String(state.idEvent + 1), action.payload)], idEvent: state.idEvent + 1 };
    case 'DEL_DATA':
      const indexDel = state.data.findIndex(data => data.idEvent === action.payload.idEvent);
      if (indexDel !== -1) {
        return {
          ...state,
          data: [...state.data.slice(0, indexDel), ...state.data.slice(indexDel + 1)]
        };
      }
    return state;
    case 'UPD_DATA':
      return {
        ...state,
        data: state.data.map(option => {
          if (option.idEvent === action.payload.idEvent) {
            option.title = action.payload.title
            option.description = action.payload.description
          }
          return option;
        })
      };
    default:
      return state;
  }
};

export default reducer;
