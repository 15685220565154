export function InputEvent({ nameElement, nameTitle, sizeElement, onChange }) {
  return (
    <form>
      <label htmlFor={nameElement}>{nameTitle}</label>
      <input
        id={nameElement}
        type="text"
        size={sizeElement}
        onChange={onChange}
      />
    </form>
  );
}
