export const ADD_DATA = "ADD_DATA";

export const addData = (title, description, status) => {
  return {
    type: ADD_DATA,
    payload: {
      title,
      description,
      status,
    },
  };
};

export const DEL_DATA = "DEL_DATA";

export const delData = (idEvent) => {
  return {
    type: DEL_DATA,
    payload: {
      idEvent
    },
  };
};

export const UPD_DATA = "UPD_DATA";

export const updData = (idEvent, title, description) => {
  return {
    type: UPD_DATA,
    payload: {
      idEvent,
      title,
      description
    },
  };
};