//import logo from './logo.svg';
import './App.css';
import EventListDo from './components/EventListDo'


function App() {
  console.log('App component Render')
  
  return (
    <div className="App">
      <b>Планировщик</b>
      <EventListDo />
    </div>
  );
}

export default App;
