import { useState } from "react";
import { Button } from "./Button/Button";
import { InputEvent } from "./InputEvent/InputEvent";

import { useSelector, useDispatch } from "react-redux";
import { addData, delData, updData } from "../redux/actions";


export default function EventListDo() {
  const events = useSelector((state) => state.data);

  console.log(events)

  const [allEvent, setEvents] = useState();

  const [inputTitleEvent, setInputTitleEvent] = useState("");
  const [inputDescriptionEvent, setInputDescriptionEvent] = useState("");

  const [selectedEvent, setSelectedEvent] = useState("");
  const handleSelectEvent = (event) => {
    console.log(event.target.name)
    setSelectedEvent(event.target.name);
  };
  const filterAllEventTrue = events.filter((option) => option.status);
  const filterAllEventFalse = events.filter((option) => !option.status);

  const dispatch = useDispatch();

  const handleClickAddEvent = () => {
    dispatch(addData(inputTitleEvent, inputDescriptionEvent, false));
  };
  const handleClickDelEvent = () => {
    dispatch(delData(selectedEvent));
  };
  const handleClickUpdEvent = () => {
    dispatch(updData(selectedEvent,inputTitleEvent, inputDescriptionEvent));
  };

  const handleInputTitleChange = (event) => {
    setInputTitleEvent(event.target.value);
  };

  const handleInputDescriptionChange = (event) => {
    setInputDescriptionEvent(event.target.value);
  };

  function handleClickDo() {
    const updateOptionsDo = events.map((option) => {
      if (String(option.idEvent) === selectedEvent && (option.status = true)) {
        return {
          ...option,
          status: true,
        };
      } else {
        return {
          ...option,
        };
      }
    });
    setEvents(updateOptionsDo);
  }
  function handleClickNotDo() {
    const updateOptionsNotDo = events.map((option) => {
      if (String(option.idEvent) === selectedEvent && (option.status = false)) {
        return { ...option, status: false };
      } else {
        return {
          ...option,
        };
      }
    });
    setEvents(updateOptionsNotDo);
  }
  return (
    <div>
      <InputEvent
        value="Введите заголовок события"
        nameElement="titleEvent"
        nameTitle="Ваше событие"
        sizeElement={100}
        onChange={handleInputTitleChange}
      />
      <InputEvent
        value="Введите описание события"
        nameElement="descriptionEvent"
        nameTitle="Описание события"
        sizeElement={100}
        onChange={handleInputDescriptionChange}
      />
      <br></br>
      <Button onClick={() => handleClickAddEvent()}>Добавить событие </Button>
      <Button onClick={() => handleClickDelEvent()}>Удалить событие </Button>
      <Button onClick={() => handleClickUpdEvent()}>Обновить событие </Button>
      <br></br>
      <b>Не выполненные</b>
      {filterAllEventFalse.map((option) => (
        <label key={option.idEvent} title= {option.description}>
          <input
            type="radio"
            name={option.idEvent}
            title= {option.description}
            value={option.title}
            checked={selectedEvent === String(option.idEvent)}
            onChange={handleSelectEvent}
          />
          {option.title}
        </label>
      ))}
      <br></br>
      <b>Выполненные</b>
      {filterAllEventTrue.map((option) => (
        <label key={option.idEvent} title={option.description}>
          <input
            type="radio"
            name={option.idEvent}
            title= {option.description}
            value={option.title}
            checked={selectedEvent === String(option.idEvent)}
            onChange={handleSelectEvent}
          />
          {option.title}
        </label>
      ))}
      <br></br>
      <Button onClick={handleClickDo}>Выполнена</Button>
      <Button onClick={handleClickNotDo}>Не выполнена</Button>
    </div>
  );
}
